import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/bg-image.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/fancy-check.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/snapshot-screenshot.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/step-1-image.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/step-1-number-icon.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/step-2-image.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/step-2-number-icon.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/step-3-image.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/step-3-number-icon.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Header/Header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Osnap/TryOsnapModal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/OsnapV2/Faq.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/OsnapV2/Video.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Portal/Portal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/VoteTicker.tsx")